
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import { EnumColumnConfig } from "../../controls/catalogueGrid/columns/enumColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { BooleanColumnConfig } from "@/components/controls/catalogueGrid/columns/booleanColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import store from "@/store";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
    //модель родительского элемента
    parentModel: { required: true },
    //идет расчет плана
    isCalculation: { required: false },
  },
  methods: {
    setColums() {
      if (ability.can("view", "Modules.CargoPriority")) {
        this.columns.push(
          new BooleanColumnConfig({
            dataField: "highPriority",
            caption: "Обязательно для погрузки",
          })
        );
      }

      if (ability.can("view", "Modules.CargoQueue")) {
        this.columns.push(
          new IntegerColumnConfig({
            dataField: "queuePosition",
            caption: "Порядок погрузки",
            validationRules: [requiredRule],
          })
        );
      }

      if (this.separationWeight) {
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "weight2",
            caption: "Вес нетто (кг)",
            decimalPoints: 0,
            hideFraction: true,
            validationRules: [requiredRule],
          })
        );
      } else {
        this.columns.filter((x) => x.dataField == "weight")[0].caption =
          "Вес (кг)";
        this.columns.filter(
          (x) => x.dataField == "totalUsedWeight"
        )[0].caption = "Загружено (кг)";
        this.columns.filter((x) => x.dataField == "residueWeight")[0].caption =
          "Остаток (кг)";
      }

      if (ability.can("view", "Modules.Order")) {
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "marginMin",
            caption: "Отклонение (-)",
            decimalPoints: 1,
            validationRules: [requiredRule],
          })
        );
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "margin",
            caption: "Отклонение (+)",
            decimalPoints: 1,
            validationRules: [requiredRule],
          })
        );
        this.columns.push(
          new TextColumnConfig({
            dataField: "orderDescription",
            caption: "Заказ",
          })
        );
      }
    },
    onRowPrepared(e: any) {
      if (e.rowType == "header") {
        e.rowElement.bgColor = "#E5F0FF";
      }
    },
  },
  computed: {},
  data() {
    return {
      separationWeight: false,
      // подробности работы custom summary см. на сервере в методе SetCustomSummary
      summaryConfig: {
        totalItems: [
          {
            name: "TotalSummary",
            showInColumn: "customName",
            displayFormat: "Кол-во (шт)",
            summaryType: "custom",
          },
          {
            name: "TotalSummary2",
            showInColumn: "customName",
            displayFormat: "Вес брутто (кг)",
            summaryType: "custom",
          },
          {
            name: "TotalSummary3",
            showInColumn: "customName",
            displayFormat: "Вес нетто (кг)",
            summaryType: "custom",
          },
          {
            name: "CountSummary",
            showInColumn: "count",
            column: "count",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },
          {
            name: "CountUsedSummary",
            showInColumn: "countUsed",
            column: "countUsed",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },
          {
            name: "CountresidueSummary",
            showInColumn: "residue",
            column: "residue",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },
          {
            name: "TotalWeightSummary",
            showInColumn: "count",
            column: "totalWeight",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "TotalUsedWeightSummary",
            showInColumn: "countUsed",
            column: "totalUsedWeight",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "ResidueWeightSummary",
            showInColumn: "residue",
            column: "residueWeight",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "CountSummary3",
            showInColumn: "count",
            column: "totalWeight2",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "CountUsedSummary3",
            showInColumn: "countUsed",
            column: "totalUsedWeight2",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "CountresidueSummary3",
            showInColumn: "residue",
            column: "residueWeight2",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
        ],
        groupItems: [],
      },
      editingConfig: new TableEditingConfig({
        allowDeleteAll: false,
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: true,
        allowDataFill: false,
        allowExport: false,
        allowImport: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
        allowColumnReordering: true,
        allowGrouping: false,
        allowResetSelectedRows: true,
        mode: "row",
        chooseColumns: true,
        allowExportToExcel: false,
        allowChangeScrollSettings: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      columns: [
        new TextColumnConfig({
          dataField: "customName",
          caption: "Наименование",
        }),

        new EnumColumnConfig({
          dataField: "typeDescription",
          caption: "Тип",
          enumType: "cargoType",
          modelField: "type",
          modelDescField: "typeDescription",
          descriptionColumnName: "typeDescription",
        }),

        new TextColumnConfig({
          dataField: "dimensions",
          caption: "Размеры (мм)",
        }),

        new DecimalColumnConfig({
          dataField: "weight",
          caption: "Вес брутто (кг)",
          decimalPoints: 0,
          hideFraction: true,
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "count",
          caption: "План (шт)",
          validationRules: [requiredRule],
          min: 0,
        }),

        new IntegerColumnConfig({
          dataField: "countUsed",
          caption: "Загружено (шт)",
          validationRules: [requiredRule],
          min: 0,
        }),

        new IntegerColumnConfig({
          dataField: "residue",
          caption: "Остаток (шт)",
          min: 0,
        }),

        new DecimalColumnConfig({
          dataField: "totalUsedWeight",
          caption: "Загружено брутто (кг)",
          decimalPoints: 0,
          hideFraction: true,
        }),

        new DecimalColumnConfig({
          dataField: "residueWeight",
          caption: "Остаток брутто (кг)",
          decimalPoints: 0,
          hideFraction: true,
        }),
      ],
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        horizontalScroll: true,
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        selectAllMode: "allPages",
        showCheckBoxesMode: "none",
      }),
      pageSizes: [10, 20, 50, 100],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }

    const settings = (store as any).state.settings.settings;

    if (settings) {
      this.separationWeight = settings.separationWeight;
    }

    this.setColums();
  },
});
